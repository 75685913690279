<script setup lang="ts">
import type { Game, GameType } from "@/types";

const props = defineProps<{
	game: Game & { skeleton?: boolean };
	category?: string;
	indexGame?: number;
	type?: GameType;
}>();
const emit = defineEmits<{ (event: "update"): void }>();

const {
	public: { baseImageUrl }
} = useRuntimeConfig();
const { t } = useT();
const isGuest = useIsGuest();
const loginGuard = useLoginGuard();
const { open } = useTaoModals();
const { handleOpenGame } = useOpenGame(open);
const { handleToggleToFavoriteClick } = useAddGameToFavorite({ t, toastTheme: "dark", toastPosition: "bottom-center" });

const defaultBg = "/nuxt-img/card-games/default-bg.png";
const isGameFavorite = computed(() => props.game.isFavorite);

const handleToggleToFavorite = () => {
	handleToggleToFavoriteClick(props.game);
	emit("update");
};

const handleOpenGameClick = () => {
	loginGuard({
		success() {
			handleOpenGame(props.game.slug);
		},
		fail() {
			open("LazyOModalSignup");
		}
	});
};
</script>

<template>
	<div :class="['game-card-wrap', type, { 'move-play-btn': !isGuest }]">
		<MGameTopTen v-if="type === 'tao_top_10'" :indexGame="indexGame">
			<template #game-card>
				<MGameCard
					:game="game"
					:gameImg="`${baseImageUrl}${game?.logo1x2 || game?.logo}`"
					:defaultBg="defaultBg"
					:width="{ full: 163, md: 114 }"
					:height="{ full: 286, md: 200 }"
					:isFavorite="isGameFavorite"
					@toggle-favorite="handleToggleToFavorite"
					@click.prevent="handleOpenGameClick"
				>
					<template #top>
						<MGameBadges :game="game" />
					</template>
				</MGameCard>
			</template>
		</MGameTopTen>
		<MGameCard
			v-else-if="type === 'tao_top_numbered'"
			:game="game"
			:gameImg="`${baseImageUrl}${game?.logo1x2 || game?.logo}`"
			:defaultBg="defaultBg"
			:width="163"
			:height="286"
			:isFavorite="isGameFavorite"
			@toggle-favorite="handleToggleToFavorite"
			@click.prevent="handleOpenGameClick"
		>
			<template #top>
				<MGameTopGame :indexGame="indexGame" />
			</template>
		</MGameCard>
		<MGameCardHorizontal
			v-else-if="type === 'slider_horizontal'"
			:game="game"
			:gameImgHorizontal="`${baseImageUrl}${game?.logo2x1 || game?.logo}`"
			:gameImg="`${baseImageUrl}${game?.logo}`"
			:defaultBg="defaultBg"
			:gameImgIncrease="2"
			:width="284"
			:height="174"
			:buttonName="t('Play')"
			:isFavorite="isGameFavorite"
			@toggle-favorite="handleToggleToFavorite"
			@click.prevent="handleOpenGameClick"
		>
			<template #top>
				<MGameBadges :game="game" />
			</template>
		</MGameCardHorizontal>
		<MGameCard
			v-else-if="type === 'slider'"
			:game="game"
			:gameImg="`${baseImageUrl}${game?.logo || game?.img}`"
			:defaultBg="defaultBg"
			:width="{ full: 163, md: 114 }"
			:height="{ full: 184, md: 133 }"
			:isFavorite="isGameFavorite"
			@toggle-favorite="handleToggleToFavorite"
			@click.prevent="handleOpenGameClick"
		>
			<template #top>
				<MGameBadges :game="game" />
			</template>
		</MGameCard>
		<MGameCard
			v-else-if="type === 'grid_week'"
			:game="game"
			:gameImg="`${baseImageUrl}${game?.image || game?.logo2x2 || game?.logo}`"
			:defaultBg="defaultBg"
			:width="{ full: indexGame === 0 ? 334 : 163, md: indexGame === 0 ? 236 : 114 }"
			:height="{ full: 286, md: 200 }"
			:isFavorite="isGameFavorite"
			@toggle-favorite="handleToggleToFavorite"
			@click.prevent="handleOpenGameClick"
		>
			<template #top>
				<MGameBadges :game="game" :custom-badge="indexGame === 0 && category" />
			</template>
		</MGameCard>
		<MGameCard
			v-else-if="type === 'slider_week'"
			:game="game"
			:gameImg="`${baseImageUrl}${game?.image || game?.logo2x2 || game?.imageSmall || game?.logo}`"
			:defaultBg="defaultBg"
			:width="{ full: indexGame === 0 ? 334 : 163, md: indexGame === 0 ? 236 : 114 }"
			:height="{ full: indexGame === 0 ? 376 : 184, md: indexGame === 0 ? 274 : 133 }"
			:isFavorite="isGameFavorite"
			@toggle-favorite="handleToggleToFavorite"
			@click.prevent="handleOpenGameClick"
		>
			<template #top>
				<MGameBadges :game="game" :custom-badge="indexGame === 0 && category" />
			</template>
		</MGameCard>
		<MGameCard
			v-else
			:game="game"
			:gameImg="`${baseImageUrl}${game?.logo1x2 || game?.logo}`"
			:defaultBg="defaultBg"
			:width="{ full: 163, md: 114 }"
			:height="{ full: 286, md: 200 }"
			:isFavorite="isGameFavorite"
			@toggle-favorite="handleToggleToFavorite"
			@click.prevent="handleOpenGameClick"
		>
			<template #top>
				<MGameBadges :game="game" />
			</template>
		</MGameCard>
	</div>
</template>

<style scoped lang="scss">
.game-card-horizontal {
	background: var(--gray-800);
	border-radius: 10px;
}

.game-card-wrap {
	width: fit-content;
	&.move-play-btn :deep(.game-overlay .nuxt-icon) {
		transform: translate(-52px, -50%);
	}
}
:deep(.game-card) {
	&:hover .btn-favorite {
		opacity: 1;
	}

	.image-block {
		border-radius: 10px;
		background: var(--neutral-900);
	}

	.game-overlay {
		background-color: var(--a-overlay-200);
		.nuxt-icon {
			font-size: 48px;
		}
		&:hover {
			z-index: 2;
		}
	}

	.btn-favorite {
		opacity: 0;
		background: rgba(255, 255, 255, 0.2);
		border-radius: 50%;
		padding: 6px;
		width: 48px;
		height: 48px;
		top: calc(50% - 24px);
		left: calc(50% + 4px);

		svg {
			width: 28px;
			height: 28px;
			flex-shrink: 0;
			border-radius: 8px;
			background: var(--overlay-300);
		}
	}
}
.grid_week,
.slider_week {
	&:nth-child(1) :deep(.game-card .image-block) {
		border: 2px solid var(--primary-400);
	}
}
.grid_week:nth-child(1) {
	grid-column: span 2;
}
.slider_week:nth-child(1) {
	grid-column: span 2;
	grid-row: span 2;
}
</style>
